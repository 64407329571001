header {
  width: 100%;
  height: 80px;
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

h3 {
  margin: 2%;
}

p {
  margin: 1%;
  font-size: small;
}

footer {
  width: 100%;
  height: 40px;
  color: white;
  background: black;
  display: flex;
  justify-content: center;
  margin-top: 2%;
  position: absolute;
  bottom: 0;
}

body {
  width: 100%;
}

.custom-toolbar {
  max-height: 20px;
}

.error {
  color: red;
  margin-top: 8%;
}